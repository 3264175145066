/* Ínicio da Configuração */

$color1: #ED3236;
$color2: #4E4E4E;
$colorError: #D8000C;
$colorSucess: #4F8A10;

$font1: 'Roboto', sans-serif;
$font2: 'Roboto', sans-serif;
$fontText: 18px;
$fontTextWeight: 300;

/* Fim da Configuração */

*{
    box-sizing: border-box;
}

body, p, a, ol, ul, li, {
    color: $color2;
    font-family: $font1;
    font-size: $fontText;
    font-weight: $fontTextWeight;
    line-height: 1.7;
}

h1, h2, h3, h4, h5, h6{
    font-family: $font2;
    line-height: 1.2;
}

a{
    text-decoration: none;
}

img, iframe{
    max-width: 100%;
}

label{
    display: block;
    margin-bottom: 5px;
}

input, select, textarea{
    width: 100%;
    height: 35px;
    padding: 0 5px;
    margin-bottom: 10px;
    border: 1px solid #E4E4E4;
    border-radius: 3px;
}

.container {
    width: 100% !important;
    max-width: 1200px;
    padding: 0 0;
}

.bs-callout {
    padding: 15px;
    margin-bottom: 30px;
    h4{color: #232323;font-size: 18px;}
    p {color: #232323;}
    &.bs-callout-primary {background-color: #337ab7;}
    &.bs-callout-success {background-color: #dff0d8;}
    &.bs-callout-info {background-color: #d9edf7;}
    &.bs-callout-warning {background-color: #fcf8e3;}
    &.bs-callout-danger {background-color: #f2dede;}
}

.parsley-error {
    border: 1px solid $colorError;
}

.parsley-required {
    display: none;
}

.text-center {
    text-align: center;
}

.clearfix{
    clear: both;
}

.g-recaptcha{
    display: inline-block;
}

.mask-black{
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.80);
    z-index: 98;
    -webkit-transition: 0.25s ease;
    transition: 0.25s ease;
    &.active{
        right: 0;
    }
}

.loader-action{
    pointer-events: none;
    .icon-loader{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        user-select: none;
        background-image: url('data:image/svg+xml;utf8,<svg stroke="#6b6b6b" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2"><circle cx="18" cy="18" r="18" stroke-opacity=".5"/><path d="m36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" dur="1s" from="0 18 18" repeatCount="indefinite" to="360 18 18" type="rotate"/></path></g></g></svg>');
        background-size: 24px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba(255, 255, 255, 0.80);
        z-index: 9999;
    }
}

/* Estrutura do site abaixo */

.header{
    background: rgb(250,250,250);
    @media(min-width: 1024px){
        height: 150px;
        background: -moz-linear-gradient(180deg, rgba(250,250,250,1) 50%, rgba(255,255,255,1) 50%);
        background: -webkit-linear-gradient(180deg, rgba(250,250,250,1) 50%, rgba(255,255,255,1) 50%);
        background: linear-gradient(180deg, rgba(250,250,250,1) 50%, rgba(255,255,255,1) 50%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fafafa",endColorstr="#ffffff",GradientType=1);
    }
    .logo{
        margin-top: 10px;
        @media(max-width: 1023px){
            height: 80px;
        }
    }
    .menu-button{
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }
    .first-line{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }
    .second-line{
        @media(max-width: 1023px){
            position: fixed;
            top: -100%;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #ED3236;
            z-index: 999;
            display: flex;
            flex-direction: column;
            justify-content: center;
            transition: 0.3s ease;
            @media not (display: flex){
                padding-top: 40px;
            }
            &.active{
                top: 0;
            }
            .link-menu{
                display: block;
                width: 100%;
                color: #fff;
                font-size: 22px;
                text-align: center;
                margin-top: 15px;
            }
            .close-menu{
                position: absolute;
                top: 15px;
                right: 15px;
            }
        }
    }
    .network{
        float: left;
        p{
            display: inline-block;
        }
        a{
            display: inline-block;
            line-height: 1;
            margin-left: 7px;
            vertical-align: middle;
        }
    }
    .number-phones{
        a{
            display: inline-block;
            line-height: 1;
            margin-left: 7px;
            &:first-child{
                margin-right: 40px;
            }
            img{
                display: inline-block;
                vertical-align: middle;
                margin-right: 7px;
            }
        }
    }
    .btn-affiliated{
        text-align: center;
        display: inline-block;
        min-width: 151px;
        min-height: 27px;
        line-height: 27px;
        color: #3A54A0;
        font-size: 13px;
        font-weight: 400;
        border: 2px solid #3A54A0;
        border-radius: 5px;
        transition: 0.3s ease;
        cursor: pointer;
        &:hover{
            color: #fff;
            background-color: #3A54A0;;
        }
    }
    .link-menu{
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        margin-right: 30px;
        margin-top: 43px;
        &:hover{
            color: $color1;
            @media(max-width: 1023px){
                color: #fff;
            }
        }
        &:after{
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: transparent;
        }
        &.active{
            &:after{
                background-color: $color1;
            }
        }
    }
}

.banner-main {
    position: relative;
    height: 550px;
    margin-bottom: 90px;
    @media(max-width: 1199px) {
        display: none !important;
    }
    .slick-track {
        left: 50%;
        margin-left: -960px;
        img {
            max-width: none;
        }
        .slick-active {
            img {
                z-index: 99;
                position: relative;
            }
        }
    }
    .control-arrows {
        width: 100%;
        max-width: 1270px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        pointer-events: none;
        .prev-banner,
        .next-banner {
            cursor: pointer;
            -webkit-transition: 0.2s ease;
            transition: 0.2s ease;
            pointer-events: auto;
            opacity: 0.7;
            &:hover {
                opacity: 1;
            }
        }
        .next-banner {
            float: right;
        }
    }
}

.slick-dots {
    position: relative;
    text-align: center;
    margin-top: -50px;
    z-index: 5;
    li {
        display: inline-block;
        margin-right: 5px;
        margin-left: 5px;
        &.slick-active {
            button {
                background-color: #fff;
            }
        }
        button {
            color: transparent;
            border: 2px solid #fff;
            border-radius: 100%;
            width: 17px;
            height: 17px;
            font-size: 1px;
            background-color: transparent;
            -webkit-transition: 0.3s ease;
            transition: 0.3s ease;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance:    none;
            appearance:         none;
            cursor: pointer;
        }
    }
}

.title-box{
    position: relative;
    margin-bottom: 40px;
    .title-main{
        position: relative;
        display: inline-block;
        font-size: 40px;
        font-weight: 700;
        color: #575757;
        background-color: #fff;
        padding-left: 30px;
        padding-right: 30px;

        z-index: 2;
        span{
            color: #ED3236;
        }
    }
    .line{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        height: 1px;
        transform: translate(-50%, -50%);
        background-color: #D2D2D2;
        z-index: 1;
    }
}

.box-events{
    $height: 250px;
    position: relative;
    display: inline-block;
    width: 100%;
    height: $height;
    border-radius: 5px;
    overflow: hidden;
    border: 2px solid transparent;
    outline: none;
    transition: 0.2s ease;
    &:hover{
        border: 2px solid $color1;
    }
    .cover{
        width: 100%;
        height: $height;
        object-fit: cover;
    }
    .info{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 0 15px 7px 15px;
        background: -moz-linear-gradient(0deg, rgba(0,0,0,0.7035189075630253) 3%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.7035189075630253) 3%, rgba(255,255,255,0) 100%);
        background: linear-gradient(0deg, rgba(0,0,0,0.7035189075630253) 3%, rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
        .date-hrs{
            color: #fff;
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
        }
        .title{
            color: #fff;
            font-size: 22px;
            font-weight: 700;
            line-height: 25px;
        }
        .tag{
            display: inline-block;
            color: #fff;
            text-align: center;
            font-size: 12px;
            min-width: 90px;
            min-height: 18px;
            line-height: 18px;
            padding: 0 5px;
            border-radius: 5px;
            background-color: #94181B;
        }
    }
}

.pg-home{
    .slide-events{
        display: inline-block;
        width: 100%;
        margin-bottom: 100px;
    }

    .container-category{
        padding: 55px 0 55px 0;
        margin-bottom: 190px;
        background-repeat: no-repeat;
        background-size: cover;
        .title-cat{
            color: #fff;
            font-size: 35px;
            font-weight: 700;
            line-height: 45px;
            border-left: 5px solid $color1;
            padding-left: 10px;
            margin-bottom: 30px;
            span{
                color: $color1;
                font-size: 50px;
            }
        }
        .img-detail{
            margin-bottom: -153px;
        }
    }
    .change-option{
        display: inline-block;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 15px;
        li{
            float: left;
            padding: 0 20px;
            min-height: 40px;
            line-height: 40px;
            background-color: #FAFAFA;
            cursor: pointer;
            &:hover{
                color: $color1;
            }
            &.active{
                color: #fff;
                background-color: $color1;
            }
        }
    }
    .container-sports{
        display: inline-block;
        width: 100%;
        background-color: #FAFAFA;
        padding: 15px 0 15px 0;
        border-radius: 5px;
        margin-bottom: -180px;
        box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.16);
        .box-sport{
            text-align: center;
            &:hover{
                .cover{
                    border: 2px solid $color1;
                }
                .title{
                    color: $color1;
                }
            }
            .cover{
                width: 100%;
                height: 125px;
                border-radius: 5px;
                object-fit: cover;
                border: 2px solid transparent;
                transition: 0.2s linear;
            }
            .title{
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
                height: 40px;
                overflow: hidden;
                margin-bottom: 5px;
                transition: 0.2s linear;
            }
        }
    }
}

.box-service{
    display: inline-block;
    width: 100%;
    margin-bottom: 50px;
    .cover{
        width: 100%;
        height: 250px;
        border-radius: 5px;
        margin-bottom: 5px;
        object-fit: cover;
    }
    .title{
        color: $color1;
        font-size: 22px;
        font-weight: 700;
        line-height: 25px;
        height: 25px;
        overflow: hidden;
    }
    .phones-link{
        font-size: 18px;
        font-weight: 700;
        img{
            margin-right: 7px;
        }
    }
}

.box-schedule{
    li{
        width: 50%;
        float: left;
        font-weight: 700;
        border-bottom: 1px solid #E4E4E4;
        &:nth-child(odd){
            text-align: left;
            font-weight: 300;
        }
    }
}

.container-news{
    background-color: #FAFAFA;
    padding: 80px 0 30px 0;
    margin-top: 85px;
    margin-bottom: 100px;
    @media(max-width: 1023px){
        padding: 80px 0 80px 0;
    }
    .title-container{
        display: inline-block;
        color: $color2;
        font-size: 35px;
        font-weight: 700;
        line-height: 45px;
        border-left: 5px solid $color1;
        padding-left: 10px;
        margin-bottom: 30px;
        @media(max-width: 767px){
            font-size: 25px;
            line-height: 35px;
            margin-bottom: 15px;
        }
        span{
            color: $color1;
            font-size: 50px;
            @media(max-width: 767px){
                font-size: 30px;
            }
        }
    }
    .edition{
        display: inline-block;
        float: right;
        font-size: 25px;
        font-weight: 700;
        padding-right: 10px;
        margin-top: 50px;
        border-right: 5px solid $color1;
        @media(max-width: 767px){
            display: block;
            float: none;
            margin-top: 0;
            border-right: 0px solid $color1;
        }
    }
    .mascarabgnews {
        background: url(../img/mascara-bg.png) top left no-repeat;
        min-height: 508px;
        min-height: 508px;
        padding: 16px 31px;
    }
    .btn-see-back{
        text-align: center;
        float: right;
        display: inline-block;
        min-width: 151px;
        min-height: 41px;
        line-height: 41px;
        padding: 0 20px;
        color: $color1;
        font-size: 15px;
        font-weight: 700;
        border: 2px solid $color1;
        border-radius: 5px;
        transition: 0.3s ease;
        color: #fff;
        background-color: $color1;
        margin-top: 20px;
        @media(max-width: 1023px){
            float: left;
        }
        &:hover{
            color: $color1;
            background-color: transparent;
        }
    }
}

.box-news{
    display: inline-block;
    width: 100%;
    margin-bottom: 80px;
    &:hover{
        .title{
            color: $color1;
        }
    }
    .box-cover{
        position: relative;
        overflow: hidden;
        img{
            width: 100%;
            height: 250px;
            object-fit: cover;
            border-radius: 5px;
        }
        .tag{
            position: absolute;
            left: 10px;
            bottom: 20px;
            display: inline-block;
            color: #fff;
            text-align: center;
            font-size: 12px;
            min-width: 70px;
            min-height: 18px;
            line-height: 18px;
            padding: 0 5px;
            border-radius: 5px;
            background-color: #94181B;
        }
    }
    .date{
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
    }
    .title{
        font-size: 22px;
        font-weight: 700;
        line-height: 25px;
        height: 50px;
        overflow: hidden;
    }
}

.footer{
    padding-top: 50px;
    background-color: #575757;
    .title{
        font-size: 22px;
        font-weight: 400;
        color: #fff;
        border-left: 5px solid $color1;
        padding-left: 10px;
        margin-bottom: 25px;
    }
    .menu-list{
        li{
            &:hover{
                a{
                    text-decoration: underline;
                }
            }
            &.active{
                a{
                    color: #fff;
                }
            }
            a{
                font-size: 18px;
                color: #ABABAB;
                line-height: 40px;
            }
        }
    }
    .text{
        color: #ABABAB;
        margin-bottom: 15px;
        b{
            color: #fff;
            font-weight: 700;
        }
        img{
            margin-right: 8px;
        }
    }
    .copyright {
        padding-bottom: 15px;
        padding-top: 15px;
        margin-top: 40px;
        background-color: #202020;
        @media(max-width: 767px) {
            padding-top: 5px;
            padding-bottom: 7px;
        }
        a,
        p {
            color: #fff;
            font-size: 15px;
            font-weight: 200;
            @media(max-width: 767px) {
                font-size: 12px;
            }
        }
        .panel {
            float: right;
            @media(max-width: 767px) {
                display: none;
            }
        }
    }
}

.top-page{
    display: inline-block;
    background-color: #575757;
    width: 100%;
    margin-bottom: 40px;
    .title-top{
        display: inline-block;
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 22px;
        line-height: 60px;
        padding-top: 2px;
        @media(max-width: 1199px){
            line-height: 30px;
            margin-top: 15px;
        }
    }
    .menu-list-top{
        display: inline-block;
        float: right;
        padding-top: 2px;
        @media(max-width: 1199px){
            display: block;
            float: none;
            margin-bottom: 15px;
        }
        li{
            display: inline-block;
            padding-left: 10px;
            padding-right: 10px;
            @media(max-width: 1199px){
                padding-left: 0px;
                padding-right: 20px;
            }
            &.active{
                a{
                    color: #fff;
                }
            }
            a{
                color: #ABABAB;
                font-size: 14px;
                line-height: 60px;
                @media(max-width: 1199px){
                    line-height: 1;
                }
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}

.box-inner-title{
    margin-bottom: 40px;
    @media(min-width: 768px){
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    @media(max-width: 767px){
        margin-bottom: 15px;
    }
    .title{
        width: 100%;
        display: inline-block;
        font-size: 35px;
        font-weight: 700;
        line-height: 45px;
        border-left: 5px solid $color1;
        padding-left: 10px;
        flex-shrink: 2;
        @media(max-width: 767px){
            font-size: 25px;
            line-height: 35px;
        }
        span{
            color: $color1;
            font-size: 50px;
            @media(max-width: 767px){
                font-size: 30px;
            }
        }
    }
    p{
        width: 100%;
        flex-shrink: 1;
        padding-left: 30px;
        @media(max-width: 767px){
            padding-left: 0;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
    select{
        width: 200px;
        @media(max-width: 767px){
            margin-top: 10px;
        }
    }
}

.title-main2{
    font-size: 35px;
    font-weight: 700;
    line-height: 45px;
    border-left: 5px solid $color1;
    padding-left: 10px;
    margin-bottom: 30px;
    @media(max-width: 767px){
        font-size: 25px;
        line-height: 35px;
    }
    span{
        color: $color1;
        font-size: 50px;
        @media(max-width: 767px){
            font-size: 30px;
        }
    }
}


.box-result-pagination{
    margin-top: 40px;
    padding-top: 15px;
    border-top: 1px solid #D2D2D2;
}

.counter-result {
    display: inline-block;
    margin: 0 15px 0 0;
    span {
        color: $color1;
        font-weight: 700;
    }
}

.pagination {
    display: inline-block;
    float: right;
    @media(max-width: 767px) {
        width: 100%;
        text-align: center;
    }
    li {
        display: inline-block;
        margin-right: 5px;
        &:last-child {
            margin-right: 0px;
        }
        &:hover {
            a {
                background-color: #fff;
            }
        }
        &.active {
            a {
                color: #fff;
                background-color: $color1;
                border: 1px solid $color1;
            }
        }
        &.dots {
            a {
                background: transparent;
                border: transparent;
            }
        }
        a {
            display: block;
            border: 1px solid #E2E2E2;
            border-radius: 3px;
            padding: 3px 13px;
            -webkit-transition: 0.2s ease;
            transition: 0.2s ease;
            @media(min-width: 375px) and (max-width: 450px) {
                padding: 6px 10px;
                font-size: 12px;
            }
            @media(max-width: 374px) {
                padding: 5px 6px;
                font-size: 12px;
            }
        }
    }
}

.pg-list-news{
    padding-bottom: 95px;
    .box-news{
        margin-bottom: 40px;
    }
}

.pg-inner-news{
    padding-bottom: 95px;
    .title{
        font-size: 30px;
        font-weight: 600;
        color: $color1;
        text-align: center;
        @media(max-width: 767px){
            line-height: 1.2;
            margin-bottom: 10px;
        }
    }
    .date{
        position: relative;
        text-align: center;
        b{
            position: relative;
            display: inline-block;
            font-size: 14px;
            font-weight: 600;
            margin: 0 auto;
            color: #000000;
            background-color: #fff;
            padding: 0 15px;
            z-index: 2;
        }
        span{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 510px;
            height: 1px;
            background-color: #D2D2D2;
            z-index: 1;
        }
    }
    .cover-new{
        display: block;
        margin: 15px auto 30px auto;
    }
    .text{
        p{
            margin: 20px 0;
        }
    }
    .share-links{
        border-top: 1px solid #E9EBEE;
        margin-bottom: 20px;
        img{
            margin-top: 15px;
        }
    }
    .fb-comments{
        display: inline-block;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 60px;
    }
    .gallery-news{
        display: inline-block;
        width: 100%;
        margin-top: 15px;
        .photo{
            width: 100%;
            height: 150px;
            object-fit: cover;
            margin-bottom: 20px;
            filter: grayscale(1);
            transition: 0.2s ease;
            @media(max-width: 567px){
                height: 100px;
            }
            &:hover{
                filter: grayscale(0);
            }
        }
    }
}

.pg-contact{
    padding-bottom: 95px;
    .title-schedule{
        color: $color1;
        font-size: 22px;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    .text-link{
        display: block;
        margin-bottom: 10px;
        img{
            margin-right: 10px;
        }
    }
    .title-box{
        margin-top: 80px;
    }
    .title-form{
        color: $color1;
        font-size: 22px;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    textarea{
        height: 110px;
        resize: none;
    }
    .btn-send{
        min-width: 100%;
        min-height: 66px;
        line-height: 66px;
        background-color: $color1;
        border-radius: 4px;
        padding-top: 3px;
        color: #fff;
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;
        border: 0;
        img{
            margin-right: 10px;
        }
    }
}

.pg-modalities{
    padding-bottom: 95px;
    .box-sport{
        text-align: center;
        &:hover{
            .cover{
                border: 2px solid $color1;
            }
            .title{
                color: $color1;
            }
        }
        .cover{
            width: 100%;
            height: 240px;
            border-radius: 5px;
            object-fit: cover;
            border: 2px solid transparent;
            transition: 0.2s linear;
        }
        .title{
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            height: 40px;
            overflow: hidden;
            margin-bottom: 15px;
            transition: 0.2s linear;
        }
    }
}

.pg-inner-modalities{
    padding-bottom: 90px;
    .sub-title{
        color: $color1;
        font-size: 22px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .cover{
        width: 100%;
        height: 400px;
        object-fit: cover;
        border-radius: 4px;
        margin-bottom: 25px;
        @media(max-width: 1023px){
            margin-top: 30px;
        }
        @media(max-width: 567px){
            height: 300px;
        }
    }
    .see-calendar{
        display: inline-block;
        width: 100%;
        margin-top: 5px;
        @media(max-width: 1023px){
            margin-bottom: 20px;
        }
        img{
            float: left;
            margin-right: 10px;
        }
        a{
            display: inline-block;
            font-size: 18px;
            font-weight: 500;
            color: $color1;
            line-height: 18px;
        }
    }
    .title-box{
        margin-top: 50px;
    }
}

.pg-materials{
    padding-bottom: 95px;
    .box-materials{
        margin-bottom: 40px;
        .cover{
            width: 100%;
            height: 170px;
            object-fit: cover;
        }

    }
    .sub-title{
        color: $color1;
        font-size: 22px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .link-download{
        display: block;
        &:hover{
            color: $color1;
        }
    }
}

.pg-list-schedule-events{
    padding-bottom: 95px;
    .box-events{
        margin-bottom: 30px;
    }
}

.pg-inner-schedule-events{
    padding-bottom: 95px;
    b{
        font-weight: 600;
        color: $color1;
    }
    .cover{
        width: 100%;
        height: 420px;
        object-fit: cover;
        border-radius: 4px;
        @media(max-width: 1023px){
            margin-top: 30px;
        }
        @media(max-width: 567px){
            height: 320px;
        }
    }
}

.mask-modal{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    top: -100%;
    left: 0;
    z-index: 9999;
    transition: 0.2s ease;
    cursor: pointer;
    &.active{
        top: 0;
    }
}

.container-modal-login{
    position: fixed;
    left: 50%;
    top: -100%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 445px;
    padding: 30px 15px;
    border-radius: 4px;
    background-color: #fff;
    z-index: 9999;
    transition: 0.2s ease;
    @media(max-width: 430px){
        width: 95%;
    }
    &.active{
        top: 50%;
    }
    .title-modal{
        color: $color1;
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 10px;
    }
    .close-modal{
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        z-index: 99999;
    }
    input{
        outline: none;
    }
    .btn-login{
        text-align: center;
        display: inline-block;
        min-width: 151px;
        min-height: 35px;
        line-height: 35px;
        color: #fff;
        font-size: 13px;
        font-weight: 400;
        border: 2px solid $color1;
        border-radius: 5px;
        background-color: $color1;
        transition: 0.3s ease;
        margin-top: 10px;
        cursor: pointer;
        &:hover{
            color: $color1;
            background-color: #fff;
        }
    }
    .id-document{
        width: 200px;
    }
    .number-blocked{
        width: 40px;
        text-align: center;
    }
    .cpf{
        width: 255px;
    }
}

.pg-account{
    padding-bottom: 95px;
    .menu-list-top{
        .name{
            font-size: 22px;
            font-size: 700;
            color: #fff;
            &:hover{
                text-decoration: none;
            }
        }
    }
    .sub-title{
        color: $color1;
        font-size: 22px;
        font-weight: 500;
        text-transform: uppercase;
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 5px;
        margin-bottom: 20px;
    }
    .box-info{
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 30px;
        margin-bottom: 0px;
        .red{
            color: $color1;
        }
        .blue{
            color: blue;
        }
        .table{
            width: 100%;
            th{
                text-align: left;
                font-weight: 600;
                background-color: #fff4f4;
                padding: 5px 10px;
            }
            td{
                padding: 5px 10px;
                @media(max-width: 567px){
                    width: 100%;
                }
            }
            tr{
                &:nth-child(odd){
                    background-color: #f7f7f7;
                }
            }
        }
    }
}

.subtitle-red{
    color: $color1;
    font-size: 22px;
    font-weight: 500;
}

.pg-company{
    padding-bottom: 95px;
    .title-box{
        margin-top: 60px;
    }
    .cover-company{
        width: 100%;
        height: 350px;
        object-fit: cover;
    }
    .slide-company{
        margin-bottom: 35px;
        height: 350px;
        @media(max-width: 1023px){
            margin-top: 30px;
        }
    }
    .slide-history{
        height: 428px;
        margin-left: 40px;
        border-radius: 5px;
        margin-bottom: 40px;
        border: 4px solid #FFFFFF;
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.16);
        @media(max-width: 1023px){
            margin-left: 0px;
        }
        .cover-history{
            width: 570px;
            height: 420px;
            object-fit: cover;
        }
    }
    .text-history{
        margin-left: 40px;
        font-style: italic;
        margin-bottom: 125px;
        @media(max-width: 1023px){
            margin-left: 0px;
            margin-bottom: 0px;
        }
    }
    .description-history{
        margin-bottom: 125px;
        @media(max-width: 1023px){
            margin-bottom: 30px;
        }
    }
    .history-box{
        width: 100%;
        display: block;
        margin-top: 45px;
        margin-bottom: 35px;
        .logo-history{
            display: inline-block;
        }
        p{
            display: inline-block;
            margin-left: 25px;
            span{
                color: $color1;
                font-size: 80px;
                font-weight: 900;
                line-height: 1.07;
            }
        }
    }
    .macbook{
        margin-bottom: 65px;
    }
}
.pg-parking{
    padding-bottom: 95px;
    .parking{
        margin-bottom: 40px;
    }
    .box-schedule{
        width: 300px;
    }
}
.pg-championship{
    padding-bottom: 95px;
    .box-championship{
        margin-bottom: 30px;
        .cover{
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
        .title{
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            color: $color1;
        }
        b{
            font-weight: 700;
        }
        .btn-infos{
            display: inline-block;
            min-width: 190px;
            min-height: 40px;
            line-height: 40px;
            font-weight: 700;
            font-size: 15px;
            color: #fff;
            background-color: $color1;
            border-radius: 5px;
            padding: 0 10px;
            margin: 20px auto 0;
            transition: 0.2s ease;
            border: 2px solid transparent;
            &:hover{
                background: #fff;
                color: $color1;
                border: 2px solid $color1;
            }
        }
    }
}
.pg-medical-exam{
    padding-bottom: 95px;
    .cover{
        object-fit: cover;
        width: 100%;
        height: 465px;
        @media(max-width: 767px){
            height: 300px;
        }
    }
    .text-description{
        p{
            margin: 20px 0;
        }
    }
    .norms-text{
        margin: 15px 0 25px;
    }
    .box-schedule{
        width: 300px;
    }
}
.pg-news-comercial{
    padding-bottom: 95px;
    .box-news-pdf{
        display: inline-block;
        width: 100%;
        margin-bottom: 30px;
        text-align: center;
        .cover{
            transition: .2s ease;
        }
        .title{
            font-size: 18px;
            font-weight: 500;
            color: #575757;
        }
        .edition{
            font-size: 18px;
            font-weight: 300;
            color: #575757;
            span{
                font-size: 22px;
                font-weight: 500;
                color: $color1;
            }
        }
        &:hover{
            .cover{
                transform: rotate(5deg);
            }
        }
    }
}

.pg-download{
    .box-downloads{
        display: inline-block;
        .cover{
            display: inline-block;
            object-fit: cover;
            width: 170px;
            height: 170px;
        }
        .title{
            display: inline-block;
            vertical-align: top;
            font-size: 22px;
            font-weight: 500;
            color: $color1;
        }
        .type{
            width: 50%;
            display: inline-block;
        }
    }
}
.pg-balancetes{
    padding-bottom: 95px;
    .balancete-item{
        margin-bottom: 30px;
        li{
            display: inline-block;
            margin-right: 75px;
            .balancete-block{
                .icon-download{
                    filter: grayscale(1);
                    transition: .2s ease;
                }
                p{
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 300;
                    color: #575757;
                }
                &:hover{
                    .icon-download{
                        transition: .2s ease;
                        filter: grayscale(0);
                    }
                    p{
                        color: $color1;
                    }
                }
            }
        }
    }
    .title-year{
        font-size: 22px;
        font-weight: 500;
        color: $color1;
        margin-bottom: 10px;
    }
}
.pg-schedule{
    padding-bottom: 95px;
    .cover-schedule{
        position: relative;
        .cover{
            object-fit: cover;
            height: 140px;
            width: 100%;
        }
        .title{
            font-size: 22px;
            font-weight: 500;
            color: #fff;
            position: absolute;
            top: 5px;
            left: 5px;
        }
    }
    .box-schedule{
        li{
            width: 100%;
            float: none;
            &:last-child{
                margin-bottom: 40px;
            }
        }
    }
}
.pg-work-with-us{
    .description-work{
        margin-top: 20px;
        margin-bottom: 45px;
    }
    .btn-archive{
        display: inline-block;
        min-width: 170px;
        min-height: 45px;
        font-size: 15px;
        font-weight: 300;
        line-height: 40px;
        border: 0;
        border-radius: 5px;
        background-color: $color1;
        color: #fff;
        margin-bottom: 30px;
        img{
            vertical-align: text-bottom;
            margin-top: 3px;
            transform: translateY(5px)translateX(5px);
        }
    }
    .archives-selected{
        margin-left: 20px;
    }
    form{
        input{
            margin-bottom: 25px;
        }
        button{
            display: inline-block;
            min-width: 470px;
            min-height: 65px;
            font-size: 16px;
            font-weight: 300;
            text-align: center;
            border: 0;
            border-radius: 5px;
            line-height: 40px;
            background-color: $color1;
            color: #fff;
            margin-bottom: 30px;
            img{
                vertical-align: text-bottom;
                margin-right: 10px;
            }
        }
    }
}
.pg-contact-president{
    padding-bottom: 95px;
    .president-infos{
        float: left;
        display: inline-block;
        margin-top: 20px;
        .subtitle-red{
            font-size: 16px;
        }
        .cover{
            width: 167px;
            height: 167px;
            border-radius: 100%;
            object-fit: cover;
            margin-top: 75px;
            @media(max-width: 1023px){
                margin-top: 20px;
            }
        }
    }
    .description-president{
        display: inline-block;
        margin-top: 90px;
        @media(max-width: 1023px){
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }
    .title-schedule{
        color: $color1;
        font-size: 22px;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    .text-link{
        display: block;
        margin-bottom: 10px;
        img{
            margin-right: 10px;
        }
    }
    .title-box{
        margin-top: 80px;
    }
    .title-form{
        color: $color1;
        font-size: 22px;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    textarea{
        height: 110px;
        resize: none;
    }
    .btn-send{
        min-width: 100%;
        min-height: 66px;
        line-height: 66px;
        background-color: $color1;
        border-radius: 4px;
        padding-top: 3px;
        color: #fff;
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;
        border: 0;
        img{
            margin-right: 10px;
        }
    }
}
.pg-inner-championship{
    padding-bottom: 95px;
    b{
        font-weight: 600;
        color: $color1;
    }
    .cover{
        width: 100%;
        height: 420px;
        object-fit: cover;
        border-radius: 4px;
    }
    .description-championship{
        margin-bottom: 20px;
    }
    .subtitle-red{
        margin-top: 60px;
    }
    .btn-tabela{
        display: inline-block;
        min-width: 190px;
        min-height: 40px;
        line-height: 40px;
        font-weight: 700;
        font-size: 15px;
        text-align: center;
        color: #fff;
        background-color: $color1;
        border-radius: 5px;
        padding: 0 10px;
        margin-top: 20px;
        margin-left: 50%;
        transform: translateX(-50%);
        transition: 0.2s ease;
        border: 2px solid transparent;
        &:hover{
            background: #fff;
            color: $color1;
            border: 2px solid $color1;
        }
    }
    .table-camp{
        width: 100%;
        @media(max-width: 1023px){
            margin-top: 30px;
        }
        .table-header{
            width: 100%;
            background-color: #575757;
            color: #fff;
            th{
                text-align: center;
                text-transform: uppercase;
                font-weight: 500;
                padding: 5px 10px;
                @media(max-width: 567px){
                    font-size: 14px;
                }
            }
            & th:first-child{
                text-align: left;
            }
        }
        .table-row{
            width: 100%;
            color: #575757;
            padding: 5px 10px;
            &:nth-child(2){
                color: #12AC3B;
                font-weight: 500;
            }
            &:nth-child(3){
                color: #5B98C1;
                font-weight: 500;
            }
            td{
                text-align: right;
                padding: 5px 15px;
                @media(max-width: 567px){
                    font-size: 14px;
                }
            }
            & td:first-child{
                text-align: left;
            }
            & td:nth-child(2){
                color: #12AC3B;
                font-weight: 500;
                text-align: center;
            }
            & td:nth-child(3){
                color: $color1;
                font-weight: 500;
                text-align: center;
            }
        }
    }
    .documento{
        font-size: 18px;
        img{
            vertical-align: initial;
        }
    }
}
.pg-secretary{
    padding-bottom: 95px;
    .description{
        margin: 40px 0 25px;
    }
    .box-schedule{
        width: 300px;
    }
    .subtitle-red{
        @media(max-width: 1023px){
            margin-top: 30px;
        }
    }
    .box-price-table{
        padding: 15px;
        text-align: center;
        background-color: #575757;
        color: #FFF;
    }
    .price{
        width: 50%;
        float: left;
        margin-bottom: 45px;
        @media(max-width: 1023px){
            width: 100%;
            margin-bottom: 0;
        }
        li{
            width: 50%;
            float: left;
            text-align: right;
            padding: 5px 15px;
            @media(max-width: 767px){
                width: 100%;
            }
            &:nth-child(odd){
                text-align: left;
                @media(max-width: 1023px){
                    background-color: #f7f7f7;
                }
            }
        }
    }
}

.first-item-mobile{
    @media(max-width: 1023px){
        margin-top: 50px;
    }
}

.pg-cipa{
    padding-bottom: 95px;
}

.box-people{
    text-align: center;
    margin-bottom: 30px;
    .cover{
        width: 100%;
        height: 315px;
        object-fit: cover;
    }
    .name{
        color: #ED3236;
        font-size: 22px;
        font-weight: 600;
    }
}

.pg-administration{
    padding-bottom: 95px;
    .box-inner-title{
        margin-top: 40px;
    }
}
